import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { DanhMucThanhVienComponent } from './danh-muc-thanh-vien/danh-muc-thanh-vien.component';
import { DanhMucChucNangComponent } from './danh-muc-chuc-nang/danh-muc-chuc-nang.component';
import { LoginComponent } from './login/login.component';
import { ThanhVienComponent } from './thanh-vien/thanh-vien.component';
import { ThanhVienThongTinComponent } from './thanh-vien-thong-tin/thanh-vien-thong-tin.component';
import { DanhMucUngDungComponent } from './danh-muc-ung-dung/danh-muc-ung-dung.component';
import { ThanhVienLichSuTruyCapComponent } from './thanh-vien-lich-su-truy-cap/thanh-vien-lich-su-truy-cap.component';
import { ThanhVienTapTinDinhKemComponent } from './thanh-vien-tap-tin-dinh-kem/thanh-vien-tap-tin-dinh-kem.component';

const routes: Routes = [
  { path: '', redirectTo: '/Login', pathMatch: 'full' },
  {
    path: 'Login', component: LoginComponent,
  },
  {
    path: 'Homepage', component: HomepageComponent,
  },
  {
    path: 'DanhMucChucNang', component: DanhMucChucNangComponent,
  },
  {
    path: 'DanhMucThanhVien', component: DanhMucThanhVienComponent,
  },
  {
    path: 'DanhMucUngDung', component: DanhMucUngDungComponent,
  },

  {
    path: 'ThanhVien', component: ThanhVienComponent,
  },
  {
    path: 'ThanhVienLichSuTruyCap', component: ThanhVienLichSuTruyCapComponent,
  },
  {
    path: 'ThanhVienTapTinDinhKem', component: ThanhVienTapTinDinhKemComponent,
  },
  {
    path: 'ThanhVienThongTin', component: ThanhVienThongTinComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }









































































