import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienTapTinDinhKem } from 'src/app/shared/ThanhVienTapTinDinhKem.model';
import { ThanhVienTapTinDinhKemService } from 'src/app/shared/ThanhVienTapTinDinhKem.service';


import { DanhMucUngDung } from 'src/app/shared/DanhMucUngDung.model';
import { DanhMucUngDungService } from 'src/app/shared/DanhMucUngDung.service';

@Component({
  selector: 'app-thanh-vien-tap-tin-dinh-kem',
  templateUrl: './thanh-vien-tap-tin-dinh-kem.component.html',
  styleUrls: ['./thanh-vien-tap-tin-dinh-kem.component.css']
})
export class ThanhVienTapTinDinhKemComponent implements OnInit {

  @ViewChild('ThanhVienTapTinDinhKemSort') ThanhVienTapTinDinhKemSort: MatSort;
  @ViewChild('ThanhVienTapTinDinhKemPaginator') ThanhVienTapTinDinhKemPaginator: MatPaginator;

  constructor(
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ThanhVienService: ThanhVienService,
    public ThanhVienTapTinDinhKemService: ThanhVienTapTinDinhKemService,

    public DanhMucUngDungService: DanhMucUngDungService,
  ) { }

  ngOnInit(): void {
    this.ThanhVienService.GetLogin();
    this.DanhMucUngDungSearch();
  }
  DanhMucUngDungSearch() {
    this.ThanhVienTapTinDinhKemService.IsShowLoading = true;
    this.DanhMucUngDungService.GetSQLByThanhVienID_ActiveToListAsync().subscribe(
      res => {
        this.DanhMucUngDungService.List = (res as ThanhVienTapTinDinhKem[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.ThanhVienTapTinDinhKemService.IsShowLoading = false;
      },
      err => {
        this.ThanhVienTapTinDinhKemService.IsShowLoading = false;
      }
    );
  }

  ThanhVienTapTinDinhKemSearch() {
    this.ThanhVienTapTinDinhKemService.IsShowLoading = true;    
    this.ThanhVienTapTinDinhKemService.GetByDanhMucUngDungIDToListAsync().subscribe(
      res => {
        this.ThanhVienTapTinDinhKemService.List = (res as ThanhVienTapTinDinhKem[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.ThanhVienTapTinDinhKemService.DataSource = new MatTableDataSource(this.ThanhVienTapTinDinhKemService.List);
        this.ThanhVienTapTinDinhKemService.DataSource.sort = this.ThanhVienTapTinDinhKemSort;
        this.ThanhVienTapTinDinhKemService.DataSource.paginator = this.ThanhVienTapTinDinhKemPaginator;
        this.ThanhVienTapTinDinhKemService.IsShowLoading = false;
      },
      err => {
        this.ThanhVienTapTinDinhKemService.IsShowLoading = false;
      }
    );
  }
  ThanhVienTapTinDinhKemAdd(element: ThanhVienTapTinDinhKem) {
    this.DownloadService.OpenWindow(element.FileName);
    this.ThanhVienTapTinDinhKemService.IsShowLoading = true;
    element.Display = this.ThanhVienService.FormDataLogin.Name;
    this.ThanhVienTapTinDinhKemService.FormData = element;
    this.ThanhVienTapTinDinhKemService.SendMailAsync().subscribe(
      res => {
        this.ThanhVienTapTinDinhKemService.FormData = res as ThanhVienTapTinDinhKem;                
        this.ThanhVienTapTinDinhKemService.IsShowLoading = false;
      },
      err => {
        this.ThanhVienTapTinDinhKemService.IsShowLoading = false;
      }
    );
  }
}
