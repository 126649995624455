<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThanhVienLichSuTruyCap" data-bs-toggle="tab"
                                    href="#ThanhVienLichSuTruyCap" role="tab" aria-controls="ThanhVienLichSuTruyCap"
                                    aria-selected="true">
                                    <span class="badge bg-danger" style="font-size: 16px;">Truy cập</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThanhVienLichSuTruyCap" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">Bắt đầu</label>
                                        <input
                                            [ngModel]="ThanhVienLichSuTruyCapService.BaseParameter.BatDau | date:'yyyy-MM-dd'"
                                            (ngModelChange)="BatDau($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                                            type="date" class="form-control" autocomplete="off">
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">Kết thúc</label>
                                        <input
                                            [ngModel]="ThanhVienLichSuTruyCapService.BaseParameter.KetThuc | date:'yyyy-MM-dd'"
                                            (ngModelChange)="KetThuc($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                                            type="date" class="form-control" autocomplete="off">
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ThanhVienLichSuTruyCapService.BaseParameter.SearchString"
                                            class="form-control" (keyup.enter)="ThanhVienLichSuTruyCapSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a style="width: 100%;" class="btn btn-success" title="Tìm..."
                                                (click)="ThanhVienLichSuTruyCapSearch()"><i
                                                    class="bi bi-search"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-danger">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Lịch sử truy cập
                                            <span
                                                *ngIf="ThanhVienLichSuTruyCapService.List">({{ThanhVienLichSuTruyCapService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ThanhVienLichSuTruyCapSort="matSort"
                                                    [dataSource]="ThanhVienLichSuTruyCapService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="ThanhVienLichSuTruyCapPaginator">
                                                                {{ThanhVienLichSuTruyCapPaginator.pageSize *
                                                                ThanhVienLichSuTruyCapPaginator.pageIndex + i + 1}}
                                                                [{{element.ID}}]
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thành viên
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Name}} [{{element.ParentID}}] [{{element.Code}}]
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="URL">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đường dẫn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a target="_blank" href="{{element.URL}}"
                                                                title="{{element.URL}}">{{element.URL}}</a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayGhiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.NgayGhiNhan | date:'MM/dd/yyyy hh:mm:ss'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Description">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>IP address
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.Description}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienLichSuTruyCapService.DisplayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienLichSuTruyCapService.DisplayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ThanhVienLichSuTruyCapPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ThanhVienLichSuTruyCapService.IsShowLoading"></app-loading>