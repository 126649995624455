<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThanhVienTapTinDinhKem" data-bs-toggle="tab"
                                    href="#ThanhVienTapTinDinhKem" role="tab" aria-controls="ThanhVienTapTinDinhKem"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Tài liệu</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThanhVienTapTinDinhKem" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Ứng dụng</label>
                                        <select class="form-select"
                                            name="ThanhVienTapTinDinhKemService.BaseParameter.DanhMucUngDungID"
                                            [(ngModel)]="ThanhVienTapTinDinhKemService.BaseParameter.DanhMucUngDungID">
                                            <option *ngFor="let item of DanhMucUngDungService.List;" [value]="item.ID">
                                                {{item.Name}} [{{item.ID}}]</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-7 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input [(ngModel)]="ThanhVienTapTinDinhKemService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ThanhVienTapTinDinhKemSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="ThanhVienTapTinDinhKemSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span
                                                *ngIf="ThanhVienTapTinDinhKemService.List">({{ThanhVienTapTinDinhKemService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ThanhVienTapTinDinhKemSort="matSort"
                                                    [dataSource]="ThanhVienTapTinDinhKemService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="ThanhVienTapTinDinhKemPaginator">
                                                                {{ThanhVienTapTinDinhKemPaginator.pageSize *
                                                                ThanhVienTapTinDinhKemPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="link-primary" style="cursor: pointer;"
                                                                (click)="ThanhVienTapTinDinhKemAdd(element)"><b>{{element.Name}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="FileName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải về
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-success" style="width: 100%;"
                                                                (click)="ThanhVienTapTinDinhKemAdd(element)"><i
                                                                    class="bi bi-cloud-download"></i></a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienTapTinDinhKemService.DisplayColumns002">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienTapTinDinhKemService.DisplayColumns002;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ThanhVienTapTinDinhKemPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ThanhVienTapTinDinhKemSort="matSort"
                                                    [dataSource]="ThanhVienTapTinDinhKemService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="link-primary" style="cursor: pointer;"
                                                                        (click)="ThanhVienTapTinDinhKemAdd(element)"><b>{{element.Name}}</b></a>
                                                                </div>
                                                            </div>
                                                            <div class="row gx-4">
                                                                <div class="col-lg-4 col-sm-4 col-4">
                                                                    <a class="btn btn-success" style="width: 100%;"
                                                                        (click)="ThanhVienTapTinDinhKemAdd(element)"><i
                                                                            class="bi bi-cloud-download"></i></a>
                                                                </div>
                                                                <br />
                                                                <br />
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienTapTinDinhKemService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienTapTinDinhKemService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ThanhVienTapTinDinhKemPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ThanhVienTapTinDinhKemService.IsShowLoading"></app-loading>