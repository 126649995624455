import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThanhVienUngDung } from 'src/app/shared/ThanhVienUngDung.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThanhVienUngDungService extends BaseService{    
    
    DisplayColumns001: string[] = ['STT', 'Name', 'Active'];   
    
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "ThanhVienUngDung";
    }

    GetSQLByParentIDToListAsync() {
        let url = this.APIURL + this.Controller + '/GetSQLByParentIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }   
}

