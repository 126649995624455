import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThanhVienTapTinDinhKem } from 'src/app/shared/ThanhVienTapTinDinhKem.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThanhVienTapTinDinhKemService extends BaseService{     
    
    DisplayColumns001: string[] = ['STT', 'ID', 'DanhMucUngDungID', 'Name', 'FileName', 'Note', 'Save'];
    DisplayColumns002: string[] = ['STT', 'Name', 'FileName'];

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "ThanhVienTapTinDinhKem";
    }

    GetByParentID_DanhMucUngDungIDToListAsync() {
        let url = this.APIURL + this.Controller + '/GetByParentID_DanhMucUngDungIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }   
    SendMailAsync() {
        let url = this.APIURL + this.Controller + '/SendMailAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.FormData));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }   
}

